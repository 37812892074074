import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
    GetCompanyDocument,
    GetUserDocument,
    MeUserDocument
} from './globalVar/graphql/globalVar.hooks';
import {
    currentBranchIdVar,
    userIdVar,
    currentCompanyIdVar,
    currentUserRoleVar,
    currentUserEmailVar,
    userUsernameVar,
    currencySingVar
} from './globalVar/state';

const mainUploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_POS_URL}/graphql/`,
    credentials: 'include',
});

const bookingUploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_POS_URL}/graphql/booking`,
    credentials: 'include',
});

const localeLink = setContext((_, { headers }) => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    return {
        headers: {
            ...headers,
            'X-LOCALE': locale,
        }
    };
});

const checkAuthorization = async () => {
    try {
        const { data } = await mainClient.query({ query: MeUserDocument });
        const username = data?.me?.username ?? null;
        userUsernameVar(username);
        return username;
    } catch (error) {
        userUsernameVar(null);
        return null;
    }
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const { errorType } = operation.getContext();

    if (errorType === 'local') {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path, extensions }) => {
                console.error(`[GraphQL local error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`);
            });
        }
        if (networkError) {
            console.error(`[Network local error]: ${networkError}`);
        }
        return forward(operation);
    } else if (errorType === 'global' || errorType === undefined) {
        if (graphQLErrors) {
            graphQLErrors.forEach(async ({ path, extensions }) => {
                const username = await checkAuthorization();

                if (extensions?.category === "user") {
                    if (!username && window.location.pathname !== '/login') {
                        window.location.href = `${process.env.REACT_APP_POS_URL}/login`;
                    }
                } else if ((extensions?.category === "internal" || path?.includes("internal")) && window.location.pathname !== '/error/500') {
                    window.location.href = '/error/500';
                } else {
                    console.error(`[GraphQL error]: ${extensions?.code} at ${path}`);
                }
            });
        }

        if (networkError && window.location.pathname !== '/error/500') {
            console.error('Network Error:', networkError);
            window.location.href = '/error/500';
        }
    }
});

const mainClient = new ApolloClient({
    link: from([localeLink, errorLink, mainUploadLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    me: {
                        read() {
                            return userUsernameVar();
                        },
                    },
                    userId: {
                        read() {
                            return userIdVar();
                        },
                    },
                    currentBranchId: {
                        read() {
                            return currentBranchIdVar();
                        },
                    },
                    currentCompanyId: {
                        read() {
                            return currentCompanyIdVar();
                        }
                    },
                },
            },
        },
    }),
});

const bookingClient = new ApolloClient({
    link: from([localeLink, errorLink, bookingUploadLink]),
    cache: new InMemoryCache(),
});

const refetchUserData = async () => {
    try {
        const userResponse = await mainClient.query({ query: GetUserDocument });
        const userId = userResponse?.data?.user?.id;
        const currentBranchId = userResponse?.data?.user?.currentBranch?.id;
        const currentCompanyId = userResponse?.data?.user?.companies?.[0].id;
        const currentUserEmail = userResponse?.data?.user?.email;
        const currentUserRole = userResponse?.data?.user?.role?.id;

        userIdVar(userId);
        currentBranchIdVar(currentBranchId);
        currentCompanyIdVar(currentCompanyId);
        currentUserEmailVar(currentUserEmail);
        currentUserRoleVar(currentUserRole);

        if (currentCompanyId) {
            const companyResponse = await mainClient.query({
                query: GetCompanyDocument,
                variables: { companyId: currentCompanyId },
            });
            const companyCurrencySign = companyResponse?.data?.user?.company?.currency?.currencySign;

            currencySingVar(companyCurrencySign);
        }
    } catch (error) {
        console.error('Error fetching user or company data:', error);
    }
};

refetchUserData();

export const apolloClientMenu = mainClient;
export const refetchUserDataMenu = refetchUserData;
export const apolloClientBooking = bookingClient;
