import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import StarRating from "@services/ui-components/src/StarRating/StarRating";
import TextInput from '../TextInput/TextInput';
import {TextareaInputWithCounter} from '../index';
import {
  editServiceFormValidationSchema
} from '@me-team/common/src/components/Reviews/constants';
import {
    useGetOneReviewQuery,
    useUpdateReviewMutation
} from '@me-team/host/main/clientsHistoryVisit/graphql/clientsHistoryVisit.hooks';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';

interface FormValuesReview {
  name: string;
  text?: string;
}

interface ClientsEditReviewModalProps {
  isModalOpened: boolean;
  setIsModalOpened: (isOpened: boolean) => void;
  reviewId?: number;
  onSuccess: () => void;
  toggleShowToast?: (text: string) => void
}

const EditReviewModal: React.FC<ClientsEditReviewModalProps> = ({
                                                                  isModalOpened,
                                                                  setIsModalOpened,
                                                                  reviewId,
                                                                  onSuccess,
                                                                  toggleShowToast
                                                                }) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  const {data: oneReviewData} = useGetOneReviewQuery({
    variables: {
      id: reviewId,
      companyId: +currentCompanyId,
    },
  });

  const [review] = oneReviewData?.user?.company?.reviews?.reviews || [];

  const [updateReview] = useUpdateReviewMutation();

  const initialValues: FormValuesReview = {
    name: review?.name,
    text: review?.text
  }

  const handleReviewSubmit = async (input: FormValuesReview) => {
    const params = {
      ...input,
      companyId: +currentCompanyId,
      id: review.id,
      rating: review.rating
    }

    try {
      await updateReview({
        variables: params,
      });
      setIsModalOpened(false);
      onSuccess();
      toggleShowToast(t("Saved"))
    } catch (error) {
      console.error('Error updating review:', error);
    }
  }

  return (
    <Modal
      show={isModalOpened}
      size="lg"
      onHide={() => setIsModalOpened(false)}
      centered>
      <Modal.Header
        closeButton
        className='border-0'>
        <Modal.Title className="h5">{t("Review modal and table.Edit Review")}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={editServiceFormValidationSchema(t)}
        onSubmit={handleReviewSubmit}
      >
        {({isSubmitting}) => (
          <Form>
            <Modal.Body className='text-center mb-3 pb-0 ps-4'>
              <Row className="align-items-center mt-3 pe-3">
                <Col sm={3}>
                  <p className="fs-7 fw-bold text-start">{t("Review modal and table.Employee")}</p>
                </Col>
                <Col sm={9}>
                  <p className="text-start">{review?.employee?.name}</p>
                </Col>
              </Row>
              <Row className="mt-3 pe-3">
                <Col sm={3}>
                  <p className="fs-7 fw-bold text-start">{t("Review modal and table.Rating")}</p>
                </Col>
                <Col sm={9} className="text-start">
                  <StarRating rating={review?.rating}/>
                </Col>
              </Row>
              <div className="form-group">
                <Row className="mt-3 pe-3">
                  <TextInput
                    id='editReview-name-field'
                    name='name'
                    labelCol={3}
                    inputCol={9}
                    placeholder={t('Add your name')}
                    label={<span>{t('Review modal and table.Client name')}&nbsp;<span
                      className='text-danger'>*</span></span>}
                    maxLength={50}
                  />
                </Row>
              </div>
              <div className="form-group">
                <Row className="mt-3 pe-3">
                  <TextareaInputWithCounter
                    name='text'
                    id='editReview-text-field'
                    maxLength={200}
                    labelCol={3}
                    inputCol={9}
                    placeholder={t('Review modal and table.Add Review')}
                    label={t('Review modal and table.Text')}
                  />
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className='border-0'>
              <Row className="w-100 justify-content-end m-0 ms-4 ps-2">
                <Col xs={6} lg={3} className='px-1 ps-0'>
                  <Button
                    onClick={() => setIsModalOpened(false)}
                    variant="outline-primary" type="button" disabled={isSubmitting}
                    className="w-100 fw-normal"
                  >
                    {t("Cancel")}
                  </Button>
                </Col>
                <Col xs={6} lg={3} className='px-1 pe-0'>
                  <Button
                    variant="primary" type="submit" disabled={isSubmitting}
                    className="w-100"
                  >
                    {t("Review modal and table.Edit")}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditReviewModal;
