/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetBranchesDocument = gql`
    query GetBranches($itemsPerPage: Int, $page: Int, $branchTypeId: Int, $name: String, $companyId: Int!) {
  user {
    company(id: $companyId) {
      name
      image
      branchesPaginated(
        itemsPerPage: $itemsPerPage
        page: $page
        branchTypeId: $branchTypeId
        search: $name
      ) {
        pageCount
        currentPage
        itemsPerPage
        totalCount
        branches {
          deleted
          rating
          id
          email
          image
          name
          description
          country {
            id
            name
          }
          city {
            id
            name
          }
          phones {
            phone
          }
          type {
            id
            name
          }
          website
          workSchedule
          description
          branchImages
          address
          category {
            id
            name
          }
          socialLink {
            instagram
            facebook
            youtube
            whatsapp
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      branchTypeId: // value for 'branchTypeId'
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBranchesQuery(baseOptions: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables> & ({ variables: GetBranchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
      }
export function useGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export function useGetBranchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesSuspenseQueryHookResult = ReturnType<typeof useGetBranchesSuspenseQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<GetBranchesQuery, GetBranchesQueryVariables>;
export const GetBranchDocument = gql`
    query GetBranch($id: Int, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      branchesPaginated(id: $id) {
        isCanCreateNewBranch
        branches {
          deleted
          id
          email
          image
          name
          description
          currencyCode
          onlineRegistration
          bookingQrLink
          removedOfTariff
          country {
            id
            name
          }
          city {
            id
            name
          }
          phones {
            phone
          }
          type {
            id
            name
          }
          timeZone
          website
          workSchedule
          description
          branchImages
          address
          category {
            id
            name
          }
          socialLink {
            instagram
            facebook
            youtube
            whatsapp
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBranchQuery__
 *
 * To run a query within a React component, call `useGetBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBranchQuery(baseOptions: Apollo.QueryHookOptions<GetBranchQuery, GetBranchQueryVariables> & ({ variables: GetBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
      }
export function useGetBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
        }
export function useGetBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
        }
export type GetBranchQueryHookResult = ReturnType<typeof useGetBranchQuery>;
export type GetBranchLazyQueryHookResult = ReturnType<typeof useGetBranchLazyQuery>;
export type GetBranchSuspenseQueryHookResult = ReturnType<typeof useGetBranchSuspenseQuery>;
export type GetBranchQueryResult = Apollo.QueryResult<GetBranchQuery, GetBranchQueryVariables>;
export const GetBranchTypesDocument = gql`
    query GetBranchTypes {
  branchTypes(onlyChild: true) {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetBranchTypesQuery__
 *
 * To run a query within a React component, call `useGetBranchTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBranchTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetBranchTypesQuery, GetBranchTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchTypesQuery, GetBranchTypesQueryVariables>(GetBranchTypesDocument, options);
      }
export function useGetBranchTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchTypesQuery, GetBranchTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchTypesQuery, GetBranchTypesQueryVariables>(GetBranchTypesDocument, options);
        }
export function useGetBranchTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchTypesQuery, GetBranchTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchTypesQuery, GetBranchTypesQueryVariables>(GetBranchTypesDocument, options);
        }
export type GetBranchTypesQueryHookResult = ReturnType<typeof useGetBranchTypesQuery>;
export type GetBranchTypesLazyQueryHookResult = ReturnType<typeof useGetBranchTypesLazyQuery>;
export type GetBranchTypesSuspenseQueryHookResult = ReturnType<typeof useGetBranchTypesSuspenseQuery>;
export type GetBranchTypesQueryResult = Apollo.QueryResult<GetBranchTypesQuery, GetBranchTypesQueryVariables>;
export const GetCountriesDocument = gql`
    query GetCountries {
  relations {
    countries {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export function useGetCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<typeof useGetCountriesSuspenseQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCitiesDocument = gql`
    query GetCities($countryId: Int!) {
  relations {
    cities(countryId: $countryId) {
      id
      name
    }
    timezones(countryId: $countryId) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables> & ({ variables: GetCitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export function useGetCitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesSuspenseQueryHookResult = ReturnType<typeof useGetCitiesSuspenseQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export const CreateBranchBeautySalonDocument = gql`
    mutation CreateBranchBeautySalon($companyId: Int!, $input: BranchInput!) {
  createBranch(companyId: $companyId, input: $input) {
    category {
      id
      name
    }
    name
    country {
      id
      name
    }
  }
}
    `;
export type CreateBranchBeautySalonMutationFn = Apollo.MutationFunction<CreateBranchBeautySalonMutation, CreateBranchBeautySalonMutationVariables>;

/**
 * __useCreateBranchBeautySalonMutation__
 *
 * To run a mutation, you first call `useCreateBranchBeautySalonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchBeautySalonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchBeautySalonMutation, { data, loading, error }] = useCreateBranchBeautySalonMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBranchBeautySalonMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchBeautySalonMutation, CreateBranchBeautySalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchBeautySalonMutation, CreateBranchBeautySalonMutationVariables>(CreateBranchBeautySalonDocument, options);
      }
export type CreateBranchBeautySalonMutationHookResult = ReturnType<typeof useCreateBranchBeautySalonMutation>;
export type CreateBranchBeautySalonMutationResult = Apollo.MutationResult<CreateBranchBeautySalonMutation>;
export type CreateBranchBeautySalonMutationOptions = Apollo.BaseMutationOptions<CreateBranchBeautySalonMutation, CreateBranchBeautySalonMutationVariables>;
export const UpdateBranchBeautySalonDocument = gql`
    mutation UpdateBranchBeautySalon($id: Int!, $input: BranchInput!, $restore: Boolean!) {
  updateBranch(id: $id, restore: $restore, input: $input) {
    category {
      id
      name
    }
    name
    country {
      id
      name
    }
  }
}
    `;
export type UpdateBranchBeautySalonMutationFn = Apollo.MutationFunction<UpdateBranchBeautySalonMutation, UpdateBranchBeautySalonMutationVariables>;

/**
 * __useUpdateBranchBeautySalonMutation__
 *
 * To run a mutation, you first call `useUpdateBranchBeautySalonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchBeautySalonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchBeautySalonMutation, { data, loading, error }] = useUpdateBranchBeautySalonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useUpdateBranchBeautySalonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBranchBeautySalonMutation, UpdateBranchBeautySalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBranchBeautySalonMutation, UpdateBranchBeautySalonMutationVariables>(UpdateBranchBeautySalonDocument, options);
      }
export type UpdateBranchBeautySalonMutationHookResult = ReturnType<typeof useUpdateBranchBeautySalonMutation>;
export type UpdateBranchBeautySalonMutationResult = Apollo.MutationResult<UpdateBranchBeautySalonMutation>;
export type UpdateBranchBeautySalonMutationOptions = Apollo.BaseMutationOptions<UpdateBranchBeautySalonMutation, UpdateBranchBeautySalonMutationVariables>;
export const CreateBranchRestaurantDocument = gql`
    mutation CreateBranchRestaurant($companyId: Int!, $category: String!, $name: String!, $type: String!, $description: String, $country: Int!, $city: Int, $address: String!, $email: String, $phones: [BranchPhoneInput!], $website: String, $instagram: String, $facebook: String, $youtube: String, $whatsapp: String) {
  createBranch(
    companyId: $companyId
    input: {category: $category, name: $name, type: $type, description: $description, country: $country, city: $city, address: $address, email: $email, phones: $phones, website: $website, socialLink: {instagram: $instagram, facebook: $facebook, youtube: $youtube, whatsapp: $whatsapp}}
  ) {
    category {
      id
      name
    }
    name
    country {
      id
      name
    }
  }
}
    `;
export type CreateBranchRestaurantMutationFn = Apollo.MutationFunction<CreateBranchRestaurantMutation, CreateBranchRestaurantMutationVariables>;

/**
 * __useCreateBranchRestaurantMutation__
 *
 * To run a mutation, you first call `useCreateBranchRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchRestaurantMutation, { data, loading, error }] = useCreateBranchRestaurantMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      category: // value for 'category'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      address: // value for 'address'
 *      email: // value for 'email'
 *      phones: // value for 'phones'
 *      website: // value for 'website'
 *      instagram: // value for 'instagram'
 *      facebook: // value for 'facebook'
 *      youtube: // value for 'youtube'
 *      whatsapp: // value for 'whatsapp'
 *   },
 * });
 */
export function useCreateBranchRestaurantMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchRestaurantMutation, CreateBranchRestaurantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchRestaurantMutation, CreateBranchRestaurantMutationVariables>(CreateBranchRestaurantDocument, options);
      }
export type CreateBranchRestaurantMutationHookResult = ReturnType<typeof useCreateBranchRestaurantMutation>;
export type CreateBranchRestaurantMutationResult = Apollo.MutationResult<CreateBranchRestaurantMutation>;
export type CreateBranchRestaurantMutationOptions = Apollo.BaseMutationOptions<CreateBranchRestaurantMutation, CreateBranchRestaurantMutationVariables>;
export const DeleteBranchDocument = gql`
    mutation DeleteBranch($id: Int!) {
  deleteBranch(id: $id)
}
    `;
export type DeleteBranchMutationFn = Apollo.MutationFunction<DeleteBranchMutation, DeleteBranchMutationVariables>;

/**
 * __useDeleteBranchMutation__
 *
 * To run a mutation, you first call `useDeleteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBranchMutation, { data, loading, error }] = useDeleteBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBranchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBranchMutation, DeleteBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBranchMutation, DeleteBranchMutationVariables>(DeleteBranchDocument, options);
      }
export type DeleteBranchMutationHookResult = ReturnType<typeof useDeleteBranchMutation>;
export type DeleteBranchMutationResult = Apollo.MutationResult<DeleteBranchMutation>;
export type DeleteBranchMutationOptions = Apollo.BaseMutationOptions<DeleteBranchMutation, DeleteBranchMutationVariables>;
export const GetCompanyCreateBranchDocument = gql`
    query GetCompanyCreateBranch($companyId: Int!) {
  user {
    company(id: $companyId) {
      country {
        id
        name
      }
      city {
        id
        name
      }
      address
      website
    }
  }
}
    `;

/**
 * __useGetCompanyCreateBranchQuery__
 *
 * To run a query within a React component, call `useGetCompanyCreateBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCreateBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCreateBranchQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyCreateBranchQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables> & ({ variables: GetCompanyCreateBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables>(GetCompanyCreateBranchDocument, options);
      }
export function useGetCompanyCreateBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables>(GetCompanyCreateBranchDocument, options);
        }
export function useGetCompanyCreateBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables>(GetCompanyCreateBranchDocument, options);
        }
export type GetCompanyCreateBranchQueryHookResult = ReturnType<typeof useGetCompanyCreateBranchQuery>;
export type GetCompanyCreateBranchLazyQueryHookResult = ReturnType<typeof useGetCompanyCreateBranchLazyQuery>;
export type GetCompanyCreateBranchSuspenseQueryHookResult = ReturnType<typeof useGetCompanyCreateBranchSuspenseQuery>;
export type GetCompanyCreateBranchQueryResult = Apollo.QueryResult<GetCompanyCreateBranchQuery, GetCompanyCreateBranchQueryVariables>;
export const GetCountryGeoDocument = gql`
    query GetCountryGeo {
  countryGeo {
    country {
      id
      phoneCode {
        code
      }
      currencies {
        id
      }
      timezone {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCountryGeoQuery__
 *
 * To run a query within a React component, call `useGetCountryGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryGeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryGeoQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryGeoQuery, GetCountryGeoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryGeoQuery, GetCountryGeoQueryVariables>(GetCountryGeoDocument, options);
      }
export function useGetCountryGeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryGeoQuery, GetCountryGeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryGeoQuery, GetCountryGeoQueryVariables>(GetCountryGeoDocument, options);
        }
export function useGetCountryGeoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCountryGeoQuery, GetCountryGeoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCountryGeoQuery, GetCountryGeoQueryVariables>(GetCountryGeoDocument, options);
        }
export type GetCountryGeoQueryHookResult = ReturnType<typeof useGetCountryGeoQuery>;
export type GetCountryGeoLazyQueryHookResult = ReturnType<typeof useGetCountryGeoLazyQuery>;
export type GetCountryGeoSuspenseQueryHookResult = ReturnType<typeof useGetCountryGeoSuspenseQuery>;
export type GetCountryGeoQueryResult = Apollo.QueryResult<GetCountryGeoQuery, GetCountryGeoQueryVariables>;
export type GetBranchesQueryVariables = Types.Exact<{
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  branchTypeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetBranchesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', name: string, image?: string | null, branchesPaginated?: { __typename?: 'BranchPaginated', pageCount?: string | null, currentPage?: number | null, itemsPerPage?: number | null, totalCount?: number | null, branches?: Array<{ __typename?: 'BranchProxy', deleted: boolean, rating?: number | null, id: number, email?: string | null, image?: string | null, name: string, description?: string | null, website?: string | null, workSchedule?: string | null, branchImages?: Array<string> | null, address: string, country: { __typename?: 'Country', id: string, name: string }, city?: { __typename?: 'CityProxy', id: number, name: string } | null, phones?: Array<{ __typename?: 'BranchPhoneProxy', phone: string }> | null, type?: { __typename?: 'BranchTypeProxy', id: number, name: string } | null, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, socialLink?: { __typename?: 'BranchSocialLinkProxy', instagram?: string | null, facebook?: string | null, youtube?: string | null, whatsapp?: string | null } | null }> | null } | null } | null } | null };

export type GetBranchQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetBranchQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, branchesPaginated?: { __typename?: 'BranchPaginated', isCanCreateNewBranch: boolean, branches?: Array<{ __typename?: 'BranchProxy', deleted: boolean, id: number, email?: string | null, image?: string | null, name: string, description?: string | null, currencyCode: string, onlineRegistration: boolean, bookingQrLink: string, removedOfTariff: boolean, timeZone: string, website?: string | null, workSchedule?: string | null, branchImages?: Array<string> | null, address: string, country: { __typename?: 'Country', id: string, name: string }, city?: { __typename?: 'CityProxy', id: number, name: string } | null, phones?: Array<{ __typename?: 'BranchPhoneProxy', phone: string }> | null, type?: { __typename?: 'BranchTypeProxy', id: number, name: string } | null, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, socialLink?: { __typename?: 'BranchSocialLinkProxy', instagram?: string | null, facebook?: string | null, youtube?: string | null, whatsapp?: string | null } | null }> | null } | null } | null } | null };

export type GetBranchTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBranchTypesQuery = { __typename?: 'Query', branchTypes: Array<{ __typename?: 'BranchTypeProxy', id: number, name: string, slug: string }> };

export type GetCountriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', countries?: Array<{ __typename?: 'CountryProxy', id: number, name: string }> | null } };

export type GetCitiesQueryVariables = Types.Exact<{
  countryId: Types.Scalars['Int']['input'];
}>;


export type GetCitiesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', cities?: Array<{ __typename?: 'CityProxy', id: number, name: string }> | null, timezones?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string }> | null } };

export type CreateBranchBeautySalonMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.BranchInput;
}>;


export type CreateBranchBeautySalonMutation = { __typename?: 'Mutation', createBranch: { __typename?: 'BranchProxy', name: string, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, country: { __typename?: 'Country', id: string, name: string } } };

export type UpdateBranchBeautySalonMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.BranchInput;
  restore: Types.Scalars['Boolean']['input'];
}>;


export type UpdateBranchBeautySalonMutation = { __typename?: 'Mutation', updateBranch: { __typename?: 'BranchProxy', name: string, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, country: { __typename?: 'Country', id: string, name: string } } };

export type CreateBranchRestaurantMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  category: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  type: Types.Scalars['String']['input'];
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  country: Types.Scalars['Int']['input'];
  city?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  address: Types.Scalars['String']['input'];
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  phones?: Types.InputMaybe<Array<Types.BranchPhoneInput> | Types.BranchPhoneInput>;
  website?: Types.InputMaybe<Types.Scalars['String']['input']>;
  instagram?: Types.InputMaybe<Types.Scalars['String']['input']>;
  facebook?: Types.InputMaybe<Types.Scalars['String']['input']>;
  youtube?: Types.InputMaybe<Types.Scalars['String']['input']>;
  whatsapp?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreateBranchRestaurantMutation = { __typename?: 'Mutation', createBranch: { __typename?: 'BranchProxy', name: string, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, country: { __typename?: 'Country', id: string, name: string } } };

export type DeleteBranchMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteBranchMutation = { __typename?: 'Mutation', deleteBranch: boolean };

export type GetCompanyCreateBranchQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetCompanyCreateBranchQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', address: string, website?: string | null, country?: { __typename?: 'CountryProxy', id: number, name: string } | null, city?: { __typename?: 'CityProxy', id: number, name: string } | null } | null } | null };

export type GetCountryGeoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCountryGeoQuery = { __typename?: 'Query', countryGeo?: { __typename?: 'CountryGeoProxy', country?: { __typename?: 'CountryProxy', id: number, phoneCode?: { __typename?: 'PhoneCodeProxy', code: string } | null, currencies?: Array<{ __typename?: 'CurrencyProxy', id: number }> | null, timezone?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string } | null> | null } | null } | null };
