/* eslint-disable */
import * as Types from '../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetExpiredFeaturesDocument = gql`
    query GetExpiredFeatures($companyId: Int!) {
  user {
    id
    isUserActiveWithinTariff
    company(id: $companyId) {
      id
      name
      address
      phone
      deleted
      currency {
        id
        currencySign
      }
      bookingQrLink
      isPremiumOn
      expiredPaidFeatures {
        name
        dateOfEnd
      }
    }
  }
}
    `;

/**
 * __useGetExpiredFeaturesQuery__
 *
 * To run a query within a React component, call `useGetExpiredFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpiredFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpiredFeaturesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetExpiredFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables> & ({ variables: GetExpiredFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables>(GetExpiredFeaturesDocument, options);
      }
export function useGetExpiredFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables>(GetExpiredFeaturesDocument, options);
        }
export function useGetExpiredFeaturesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables>(GetExpiredFeaturesDocument, options);
        }
export type GetExpiredFeaturesQueryHookResult = ReturnType<typeof useGetExpiredFeaturesQuery>;
export type GetExpiredFeaturesLazyQueryHookResult = ReturnType<typeof useGetExpiredFeaturesLazyQuery>;
export type GetExpiredFeaturesSuspenseQueryHookResult = ReturnType<typeof useGetExpiredFeaturesSuspenseQuery>;
export type GetExpiredFeaturesQueryResult = Apollo.QueryResult<GetExpiredFeaturesQuery, GetExpiredFeaturesQueryVariables>;
export type GetExpiredFeaturesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetExpiredFeaturesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, isUserActiveWithinTariff?: boolean | null, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, deleted: boolean, bookingQrLink: string, isPremiumOn: boolean, currency?: { __typename?: 'CurrencyProxy', id: number, currencySign?: string | null } | null, expiredPaidFeatures?: Array<{ __typename?: 'PaidFeatureProxy', name?: Types.Tariff | null, dateOfEnd?: string | null }> | null } | null } | null };
