import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {OverlayTrigger, Tooltip, Table, Form, Container, Row, Col, Button} from 'react-bootstrap';
import { Pagination, } from '@services/ui-components';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import { Formik, Form as FormFormik } from 'formik';
import PriceComponent from '../../ui-components/PriceComponent/PriceComponent';
import SalaryComponent from '../../ui-components/SalaryComponent/SalaryComponent';
import DurationInput from '../../../components/ui-components/DurationInput/DurationInput';
import ServicesModalConfirmation from '../../ui-components/ServicesModalConfirmation/ServicesModalConfirmation';
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../hooks/useToast";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar, currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import {debounce} from "@me-team/host/src/utils/utils";
import SocketConnection from "../../../services/SocketConnection";
import { EmployeeServiceRelationProxy, SalaryType, ServiceProxy } from '@me-team/host/main/graphql/types';
import {
    useCreateAllRelationsMutation,
    useCreateEmployeeServiceRelationMutation, useDeleteAllRelationsMutation, useDeleteEmployeeServiceRelationMutation,
    useGetEmployeeServicesQuery,
    useUpdateEmployeeServiceRelationMutation
} from '@me-team/host/main/employeeServices/graphql/employeeServices.hooks';
import { useGetCurrencySignQuery } from '@me-team/host/main/appointment/graphql/appointment.hooks';
import ExistingBookingsModal from "./ExistingBookingsModal";

interface Filter {
    id: number;
    page: number;
    itemsPerPage: number;
    categoryId?: number;
    serviceName?: string;
    accessibility?: boolean;
    onlineBooking?: boolean;
}
type SalaryTypeProxy = {
    salaryType?: SalaryType | number;
};
type ExtendedServiceProxy = ServiceProxy & SalaryTypeProxy;

const EmployeeServicesTable: React.FC<{ filter: Filter, }> = ({ filter, }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const checkboxRef = useRef(null);
    const navigate = useNavigate();
    const ENTITY_IMPORT_TYPE: string = 'employee-services';
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [show, setShow] = useState(false);
    const [text, setText] = useState(" ");
    const [showExistingBooking, setShowExistingBooking] = useState(false);

    const { showToast, toggleShowToast, setShowToast } = useToast();
    const [isLoadingRefetchServices, setIsLoadingRefetchServices] = useState<boolean>(false);
    const handleToggleModal = () => setShow(prevState => !prevState);
    const userRole = useReactiveVar(currentUserRoleVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const { data, loading, refetch } = useGetEmployeeServicesQuery({
        variables: {
            id: +id, page: currentPage,
            itemsPerPage: itemsPerPage,
            categoryId: filter.categoryId,
            serviceName: filter.serviceName,
            accessibility: filter.accessibility,
            onlineBooking: filter.onlineBooking,
            companyId: +currentCompanyId
        } })
    const debouncedRefetch = useCallback(
        debounce(() => {
            setIsLoadingRefetchServices(true);
            refetch().then((data) => {
                setIsLoadingRefetchServices(false);
            });
        }, 1000),
        [refetch]
    );
    useEffect(() => {
        if (currentCompanyId) {
            new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
                if (data.entity === ENTITY_IMPORT_TYPE) {
                    debouncedRefetch()
                }
            })
        }
    }, [currentCompanyId, refetch]);
    const { data: currency, loading: currencyLoading } = useGetCurrencySignQuery({
        variables: { companyId: +currentCompanyId }
    })
    const currencySign = currency?.user.company.currency.currencySign
    const selectAllData = data?.user?.company?.servicesList
    const servicesListLength = selectAllData?.length
    const employeeServicesList  = data?.user?.company?.employees?.employees[0]?.employeeServicesList?.length
    const employeeServices = data?.user?.company?.employees?.employees[0]?.employeeServices?.employeeServices
    const hasDeletedRelations = employeeServices?.some(service =>
        service.employeeServiceRelations?.some(relation => relation.deleted === true)
    );

    const isIndeterminate =
        employeeServicesList > 0 &&
        employeeServicesList < servicesListLength &&
        !hasDeletedRelations;

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = isIndeterminate;
        }
    }, [isIndeterminate]);
    const [updateServices] = useUpdateEmployeeServiceRelationMutation({
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data)
        },
        onError: (error) => {
            console.error('Error occurred during mutation:', error)
        },
        context: {
            errorType: 'local',
        },
    })
    const [createRelation] = useCreateEmployeeServiceRelationMutation({
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data)
        },
        onError: (error) => {
            console.error('Error occurred during mutation:', error)
        },
        context: {
            errorType: 'local',
        },
    })
    const [deleteRelation] = useDeleteEmployeeServiceRelationMutation({
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data)
        },
        onError: (error) => {
            console.error('Error occurred during mutation:', error)
        },
        context: {
            errorType: 'local',
        },
    })
    const [createAllRelations] = useCreateAllRelationsMutation()
    const [deleteAllRelation] = useDeleteAllRelationsMutation()
    const updateEmployeeServices = async (values: EmployeeServiceRelationProxy) => {
        const variables = {
            "duration": values.duration,
            "price": values.price,
            "onlineBooking": values.online,
            "salaryType": values.salaryType === 2 ? SalaryType.Currency : SalaryType.Percent ,
            "salaryValue": values.salaryValue
        }
        try {
            const resp = await updateServices({ variables: { "relationId": values.id, input: variables, companyId: +currentCompanyId } })
            toggleShowToast()
            refetch()
        } catch (error) {
            console.error('Update error', error)
        }
    }

    const updateOnlineBooking = (i: ServiceProxy) => {
        setSelectedEmployee(i)
        const employee  = i?.employeeServiceRelations[0]?.employee
        const onlineRegistration = i?.onlineRegistration;
        const isOnlineSignUpAvailable = i?.employeeServiceRelations[0]?.employee?.recordSetting?.isOnlineSignUpAvailable;
        switch (true) {
            case isOnlineSignUpAvailable === false && onlineRegistration !== false:
                setText(`${employee?.name} ${employee?.surname} ${t('is not available for Online Registration form. Should we make')} ${employee?.name} ${employee?.surname} ${t('available in the Online Registration form for your clients?')}`)
                break;
            case isOnlineSignUpAvailable === true && onlineRegistration === false:
                setText(`${i?.name}${t('is not available for Online Registration form. Should we make')} ${i?.name}  ${t('available in the Online Registration form for your clients?')}`)
                break;
            case isOnlineSignUpAvailable === false && onlineRegistration === false:
                setText(` ${t('Service')} ${i?.name} ${t('and')} ${employee?.name} ${employee?.surname} ${t('are not available for Online Booking form. Should we make')} ${i?.name} ${t('and')} ${employee?.name} ${employee?.surname} ${t('available in the Online Booking form for your clients')} ?`)
            break;
            default:
                updateEmployeeServices({ ...i.employeeServiceRelations[0], online: !i.employeeServiceRelations[0].online})
                return
        }
        !i.employeeServiceRelations[0].online ?
            handleToggleModal() :
            updateEmployeeServices({ ...i.employeeServiceRelations[0], online: !i.employeeServiceRelations[0].online })
    }
    const updateDuration = (i: ServiceProxy, duration: string) => {
        updateEmployeeServices({ ...i.employeeServiceRelations[0], duration: duration })
    }
    const deleteServiceRelation = async (values: ServiceProxy) => {
        try {
            const resp = await deleteRelation({ variables: { id: values.employeeServiceRelations[0].id, companyId: +currentCompanyId } })
            if (resp?.errors?.message === "Relation has bookings in future and cannot be deleted") {
                setShowExistingBooking(true)

            } else {
                toggleShowToast()
                refetch()
            }
        } catch (error) {
            console.error('delete error', error)
        }
    }
    const createServiceRelation = async (values: ExtendedServiceProxy) => {
        const variables = {
            "employeeId": +id,
            "serviceId": values.id,
            "duration": values.duration,
            "price": values.price,
            "onlineBooking": false,
            "companyId": +currentCompanyId,
        }
        try {
            const resp = await createRelation({ variables })
            toggleShowToast()
            refetch()
        } catch (error) {
            if (error.errors && error.errors[0] && error.errors[0].extensions && error.errors[0].extensions.category) {
                console.log(error.errors[0].extensions.category);
            } else {
                console.error('Update error', error);
            }
        }
    }

    const handleUpdateRelation = async (values: ServiceProxy) => {
        values.employeeServiceRelations.length > 0 && !values?.employeeServiceRelations[0]?.deleted ? deleteServiceRelation(values) : createServiceRelation(values)
    }
    let timeoutId: ReturnType<typeof setTimeout>;

    const updatePrice = (i: ServiceProxy, target: number): void => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            updateEmployeeServices({ ...i.employeeServiceRelations[0], price: +target });
        }, 1300);
    };
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const pageCount = data?.user?.company?.employees?.employees[0]?.employeeServices.pageCount
    const totalItems = data?.user?.company?.employees?.employees[0]?.employeeServices.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
    const isAnyFilterActive = (): boolean => {
        return !!filter.categoryId ||
            !!filter.serviceName ||
            !!filter.accessibility ||
            !!filter.onlineBooking;
    };
    const changeRelationForAll = async () => {
        try {
            const operation = checkServicesList || isIndeterminate ? deleteAllRelation : createAllRelations;
            const resp = await operation({
                variables: {
                    input: {
                        "employee": +id
                    },
                    companyId: +currentCompanyId,
                }
            });
            toggleShowToast();
            refetch();
        } catch (error) {
            console.error('Create relation error', error);
        }
    };
    const checkServicesList = useMemo(() => {
        return selectAllData?.every(service => service.employeeServiceRelations.length > 0) || false;
    }, [selectAllData])

    if (loading || currencyLoading ) return <Spinner />

    return (
        <>
            {employeeServices?.length === 0 ?
                <Container className="mt-5">
                    <Row className="mt-5 text-center">
                        <Col xs={12}>
                            {
                                isAnyFilterActive ?
                                    <>
                                        <h4 className="head-personal-data text-center fw-normal pt-5 mt-5 mb-4">
                                            {t("Services have not yet been created")}
                                        </h4>
                                        <Button className="px-4 py-6 text-center"
                                                onClick={() => navigate(`/services/service/${currentCompanyId}/new`)}
                                        >
                                            <i className="bi bi-plus"></i>  {t("Create service")}
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <h4 className="head-personal-data text-center fw-normal pt-5 mt-5">
                                            {t("NoDataFoundForTheGivenValues")}
                                        </h4>
                                    </>
                            }
                        </Col>
                    </Row>
                </Container>
                :
                <>
                    <Table responsive >
                        <thead>
                        <tr>
                            <th className='ps-4'>
                                <OverlayTrigger
                                    overlay={<Tooltip>{t('Select the services that this employee provides')}</Tooltip>}>
                                        <Form>
                                            <Form.Check
                                                type={"checkbox"}
                                                id={`employeeServices-selectAll-field`}
                                                label={<i className="bi bi-info-circle"> </i>}
                                                checked={employeeServicesList === servicesListLength && !hasDeletedRelations}
                                                onChange={() => changeRelationForAll()}
                                                disabled={userRole === 5}
                                                ref={checkboxRef}
                                            />
                                        </Form>

                                </OverlayTrigger>
                            </th>
                            <th>{t('Online Booking')}</th>
                            <th>{t('Service')}</th>
                            <th>{t('Category')}</th>
                            <th>{t('Duration')}</th>
                            <th>{t('Cost')}</th>
                            <th>{t('Salary')}</th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            employeeServices?.map((i, index) => (
                                <tr key={i?.name + index}>
                                    <td className='ps-4'>
                                        <Form>
                                            <Form.Check
                                                type={"checkbox"}
                                                id={`employeeServices-${"serviceCheck" + index}-field`}
                                                checked={
                                                    i.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted
                                                }
                                                onChange={() => handleUpdateRelation(i)}
                                                disabled={userRole === 5}
                                            />
                                        </Form>
                                    </td>
                                    <td>
                                        <Form>
                                            <Form.Check
                                                disabled={userRole === 5 || i.employeeServiceRelations.length === 0 || !(i.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted)  }
                                                type="switch"
                                                id={`employeeServices-${"updateOnlineBooking" + index}-field`}
                                                checked={
                                                    i.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted
                                                        ? !!i?.employeeServiceRelations[0]?.online
                                                        : false
                                                }
                                                onChange={() => updateOnlineBooking(i)}
                                            />
                                        </Form>
                                    </td>
                                    <td>
                                        {
                                            userRole === 5 ? <p className=" text-dark  mb-0 text-decoration-underline">{i?.name}</p>
                                            : <Link to={`/services/service/${currentCompanyId}/service-list/${i?.id}/edit`} >{i?.name}</Link>
                                        }
                                        <p className="text-secondary mb-0 fs-7 pe-2">{i?.price}  {currencySign}</p>
                                    </td>
                                    <td>
                                        <p className=" text-dark fs-7 mb-0">{i?.serviceCategory?.name}</p>
                                        <p className="text-secondary mb-0 fs-7 pe-2">{i?.serviceSubcategory?.name}</p>
                                    </td>
                                    <td>
                                        <div style={{ width: '94px' }} >
                                            {
                                                i?.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted  ?
                                                    <Formik
                                                        initialValues={{}}
                                                        onSubmit={values => {

                                                        }}
                                                    >
                                                        {formik => (
                                                            <FormFormik onSubmit={formik.handleSubmit}>
                                                                <DurationInput
                                                                    name="Name"
                                                                    label="label name"
                                                                    prevValue={
                                                                        i?.employeeServiceRelations[0]?.duration || i?.duration
                                                                    }
                                                                    serviceValue={i}
                                                                    updateDuration={updateDuration}
                                                                    isDisabled={userRole === 5}
                                                                />
                                                            </FormFormik>
                                                        )}

                                                    </Formik> : i?.duration.slice(0, -3)
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        {i?.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted ? <PriceComponent service={i} updateEmployeeServices={updateEmployeeServices} currencySign={currencySign} index={index}/> : i.price + ' ' + currencySign  }
                                    </td>
                                    <td>
                                        {i?.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted  ? (
                                            <SalaryComponent
                                                service={i?.employeeServiceRelations[0]}
                                                updateEmployeeServices={updateEmployeeServices}
                                                currencySign={currencySign}
                                                price={i?.employeeServiceRelations[0]?.price}
                                                value={i?.employeeServiceRelations[0]?.salaryValue}
                                            />
                                        ) : null}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table >

                    <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                        <Pagination
                            t={t}
                            pages={Number(pageCount)}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            firstItemIndex={firstItemIndex}
                            lastItemIndex={lastItemIndex}
                            totalItems={totalItems}
                        />
                    </div>
                </>
            }

            <ServicesModalConfirmation
                show={show}
                handleToggleModal={handleToggleModal}
                selectedEmployee={selectedEmployee}
                updateFunc={updateEmployeeServices}
                text={text}
            />
            <ToastComponent show={showToast} setShow={setShowToast} text={t('Saved')}/>
            <ExistingBookingsModal show={showExistingBooking} setShow={setShowExistingBooking}   />
        </>
    );
};

export default EmployeeServicesTable;
